import React, { useEffect, useState } from "react"
import { PageCenter } from "../../../components/elements/wrappers"
import { DefaultHeader, Path } from "../../../components/elements"

import { Row, Col, ListGroup, Card, Button } from "react-bootstrap"

import viewList from "./ca-benefits.json"
import { Page } from "../../../components/elements"




function windowSize() {
    if (typeof window !== "undefined") {
        const { innerWidth: width, innerHeight: height } = window
        return {
            width,
            height,
        }
    }
    return null
}

function ViewBlock({ title, children, button, href, className }) {
    if (!title || !children) return null

    return (
        <Card data-test="view-block" className={className}>
            <Card.Header as="h5">{title}</Card.Header>
            <Card.Body>
                <Card.Text>{children}</Card.Text>
                {button ? (
                    <a href={href}>
                        <Button variant="egi">{button}</Button>
                    </a>
                ) : null}
            </Card.Body>
        </Card>
    )
}

function CABenefits() {
    const [index, setIndex] = useState(0)

    const handleClick = e => {
        if (!e.target.classList.contains("active")) {
            document
                .querySelectorAll(".list-group-item")
                .forEach(e => e.classList.remove("active"))
            e.target.classList.add("active")
            setIndex(e.target.getAttribute("data-index"))
        }
    }

    return (
        <Row className="p-3 ca-benefits">
            <Col className="pr-0">
                <ListGroup className="ml-auto" style={{ width: "fit-content" }}>
                    {viewList.map((e, i) => (
                        <ListGroup.Item
                            data-index={i}
                            onClick={handleClick}
                            key={i}
                            className={i == 0 ? "active" : ""}
                        >
                            {e.title}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Col>
            <Col style={{ maxWidth: "100px" }} className="p-0">
                <Path idx={+index} height={50 * 7} />
            </Col>
            <Col className="pl-0">
                <ViewBlock
                    title={viewList[index].title}
                    button={viewList[index].button}
                    href={viewList[index].href}
                >
                    {viewList[index].description}
                </ViewBlock>
            </Col>
        </Row>
    )
}

function checkMobile() {
    return windowSize()?.width < 800
}

export default function Component() {
    const [mobile, setMobile] = useState(checkMobile())

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("resize", () => {
                setMobile(checkMobile())
            })
        }
    }, [])

    return (
        <Page
            title="Benefits & Downloads"
            description="EGI researchers collaborate with colleagues across academic, industry, and government organizations around the world to discover and improve scientific innovation and applied technologies."
        >
            <PageCenter bg="white" text="dark">
                <DefaultHeader
                    title="Corporate Associate Benefits"
                    subtitle="EGI researchers collaborate with colleagues across academic, industry, and government organizations around the world to discover and improve scientific innovation and applied technologies."
                    type="small"
                />

                {mobile ? (
                    viewList.map((e, i) => (
                        <ViewBlock
                            key={i}
                            title={viewList[i].title}
                            button={viewList[i].button}
                            className="my-4"
                        >
                            {viewList[i].description}
                        </ViewBlock>
                    ))
                ) : (
                    <CABenefits />
                )}
            </PageCenter>
        </Page>
    )
}